@import "../../../assets/colors.scss";

.version {
	padding: 20px;
	color: $primary;
	font-size: 40px;
	font-weight: bold;
	text-transform: uppercase;
}

.helpVideo {
	height: 480px;
	width: 850px;
	margin-top: 50px;
}
