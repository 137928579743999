@import "../../../../assets/colors.scss";

.checklist {
	padding-top: 10px;
	overflow-y: auto;

	.checklist-item {
		margin-top: 20px;

		.k-state-default.finished {
			text-decoration: line-through;
		}

		.manageable-field-label {
			margin-left: 5px;
			display: none;
		}

		&:hover {
			.manageable-field-label {
				display: inline;
			}
		}
	}
}

.largeButton {
	color: white;
	cursor: pointer;
	position: relative;
	width: calc(100% - 60px);
	height: 130px;
	margin: 30px 30px;
	transition: all 0.2s ease-in-out;
	background: linear-gradient(90deg, #ee7641 0%, $primary 41%, $accent 100%);
	font-weight: bold;
	border-radius: 15px;

	&:hover {
		box-shadow: 0 11px 34px -5px #cccccc;
		transform: translateY(-5px);
	}

	i {
		font-size: 200px;
		transform: rotate(10deg);
	}

	span {
		text-align: center;
		font-size: 35px;
	}
}
