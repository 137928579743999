@import "../../assets/colors.scss";

* {
	margin: 0;
}

.container {
	height: 100vh;
	width: 100vw;
	margin: 0 0;
	background-image: url("/LoginBg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.logIn {
		width: auto;
		height: auto;
		margin: auto auto;
		background-color: rgba($primary, 0.7);
		padding: 40px 30px;
		padding-bottom: 40px;

		img.logo {
			margin-bottom: 40px;
		}

		#login {
			margin-bottom: 20px;
			color: white;
			font-size: 35px;
			letter-spacing: 8px;
			margin: 0 0 20px 10px;
		}

		p {
			line-height: 155%;
			font-size: 18px;
			color: white;
			font-weight: 400;
			margin-bottom: 10px;
		}

		button {
			width: auto;
			text-align: center;
			padding: 5px 40px;
			margin-top: 5px;
			font-size: 18px;
			margin-left: auto;
			margin-right: auto;
			font-weight: 500;
			border: none;
			transition: all 0.3s ease;
			outline: 0;
		}
	}
}
