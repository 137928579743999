@import "../../assets/colors.scss";

.bodyContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 88px;
	right: 0;
	z-index: 30;
	background-color: $background-blue;
	overflow: auto;

	> div:first-child {
		width: 100%;
		height: 100%;
	}
}
