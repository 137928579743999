@import "./colors.scss";

$detailGridHeight: 450px;
$heightTransition: height ease-in-out 0.5s;

.header {
	padding: 17px;
	height: 50px;
	background-color: $primary;
	color: white;
}

.masterGrid {
	height: calc(100% - 70px - #{$detailGridHeight}) !important;
	transition: $heightTransition;
}

.detailGrid {
	height: $detailGridHeight !important;
	transition: $heightTransition;
	background-color: white;
	overflow: hidden;

	&.closed {
		height: 0px !important;
	}
}

.k-grid .k-state-selected {
	background-color: #ffffff !important;
	color: #000000;
}
