@import "../../../assets/colors.scss";

.heatRow {
	i.las.la-fire-alt {
		color: #c1c1c1;
		font-size: 30px;

		&.heatable {
			cursor: pointer;
		}

		&.heated {
			color: $primary;
		}

		&.newHeated {
			color: darken($primary, 15%);
		}
	}
}
