.editableChipList {
	.k-textbox {
		width: 250px !important;
		margin-right: 10px;
	}

	.k-chip {
		cursor: default;

		.k-icon {
			cursor: pointer;
		}
	}
}
