$primary: #e64700;
$primary-l: #2c2c2ce2;
$accent: #2c2c2c;
$accent-l: #ffdccc;
$background-blue: #e5e5e5;
$background-grey: #f7f7f7;
$font-color: #656565;
$greenBg: #1ebe38;
$greenFg: white;
$orangeBg: #f5af2b;
$orangeFg: black;
$redBg: #be1d23;
$redFg: white;
$blueBg: #3399ff;
$greyBg: #cccccc;
