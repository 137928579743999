@import "./assets/font/pfSquareSansPro.scss";

html {
	height: 100%;

	body {
		margin: 0;
		font-family: "PF Square Sans Pro Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: 100%;

		#root {
			height: 100%;
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
