.emailTabContent {
	width: 99%;

	.emailTabStrip {
		height: 350px;

		.emailBody {
			height: 220px;

			textarea {
				resize: none;
			}
		}
	}
}
