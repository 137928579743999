@import "../../../../assets/colors.scss";

.userDay {
	width: 100%;
	height: 100%;
	border: solid;
	border-color: $primary;
	border-width: 1px;
	background-color: $background-grey;
	border-radius: 5px;
}

.dayInMonth {
	width: 100%;
	min-height: 100%;
	border: solid;
	border-color: $primary-l;
	border-width: 1px;
	background-color: $background-grey;
	border-radius: 5px;
}

.userWeek {
	margin-bottom: 10px;
	min-height: "80px" !important;
}

.view {
	font-weight: bold;
	color: $primary;
	margin-bottom: 2px;
}

.userName {
	font-weight: bold;
	color: $primary;
	padding: 25px;
	text-align: center;
}

.boardHeader {
	text-align: center !important;
	margin-bottom: 10px !important;
	font-weight: bold;
	color: $primary;
}

.card {
	border: 1px solid;
	margin: 0.5rem;
	background-color: white;
	border-color: $primary-l !important;
}

.header {
	padding: 17px;
	height: 50px;
	width: 300px;
	border-radius: 5px;
	background-color: $primary;
	color: white;
}

.grid {
	overflow: auto;
	height: 100%;
	width: 300px;
}

.planningNavbar {
	padding: 17px;
	height: 100px;
	width: 100%;
}

.planningUsersGrid {
	height: 100%;
	overflow: auto;
}

.planningGrid {
	display: flex;
	flex-direction: column;
}

.multiline {
	font: inherit;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
}

.orange {
	font: inherit;
	background-color: $orangeBg !important;
	color: $redFg !important;
	border-radius: 3px;
	text-align: center !important;
}

.green {
	font: inherit;
	background-color: $greenBg !important;
	color: $greenFg !important;
	border-radius: 3px;
	text-align: center !important;
}

.red {
	font: inherit;
	background-color: $redBg !important;
	color: $redFg !important;
	border-radius: 3px;
	text-align: center !important;
}

.grey {
	font: inherit;
	background-color: $greyBg !important;
	color: $redFg !important;
	border-radius: 3px;
	text-align: center !important;
}

.blue {
	font: inherit;
	background-color: $blueBg !important;
	color: $redFg !important;
	border-radius: 3px;
	text-align: center !important;
}

%smallStatus {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	border-radius: 15px;
	color: white;
	font-size: 20px;
	margin-top: 5px;
	cursor: help;
}

.greenStatusSmall::before {
	@extend %smallStatus;
	background-color: $greenBg;
}

.orangeStatusSmall::before {
	@extend %smallStatus;
	background-color: $orangeBg;
}

.redStatusSmall::before {
	@extend %smallStatus;
	background-color: $redBg;
}

.blueStatusSmall::before {
	@extend %smallStatus;
	background-color: $blueBg;
}

.greyStatusSmall::before {
	@extend %smallStatus;
	background-color: $greyBg;
}

.groupsName {
	justify-content: center !important;
	width: 100px !important;
	padding-top: 10px;
	padding-left: 15px !important;
	padding-right: 0px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.anomalyHeader {
	font-weight: bolder;
	font-size: 16px;
	color: $primary;
	margin-bottom: 10px;
}
