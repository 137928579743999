@import "../../../../assets/colors.scss";

.rolesRow {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;

  .title {
    color: $primary;
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: 5px;
  }
}

.rolesGrouptitle {
  font-weight: 300;
  font-size: 1.5em;
  color: $accent;
  margin-bottom: 10px;
  border-bottom: 1px solid $primary;
}

.rolesItems {
  margin-bottom: 30px;
}
