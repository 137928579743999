@import "../../../../../assets/colors.scss";

.groupContainer {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.participantContainer {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.group {
	border: solid;
	border-color: $primary-l;
	border-width: 1px;
	border-radius: 5px;
	height: 75px;
	margin-bottom: 10px;
	margin-right: 5px !important;
	padding-left: 10px;
}

.participant {
	border: solid;
	border-color: $primary-l;
	border-width: 1px;
	border-radius: 5px;
	margin-bottom: 10px;
	margin-right: 5px !important;
	margin-left: 5px !important;
	padding-left: 10px;
	cursor: grab;
}

.header {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: $primary;
	line-height: 40px;
}

.multiline {
	font: inherit;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
}

.buttonStyle {
	cursor: pointer;
	font-size: 25px !important;
}

.filterRowStart {
	display: flex;
	justify-content: flex-start;
}

.filterRowEnd {
	display: flex;
	justify-content: flex-end;
}

.filter {
	font: inherit;
	font-weight: bold;
	line-height: 30px;
}