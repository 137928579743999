@import "./colors.scss";

$main-menu-bg-color: $primary;
$sidebar-menu-ul-color: white;
$main-menu-item-hover-color: $accent;
$main-menu-item-active-hover-color: $accent;
$sub-menu-bg-color: $primary-l;
$sub-menu-item-active-color: $primary;

$manageable-field-label-hover-color: $primary;

$routed-tab-panel-bg-color: white;
$routed-tab-panel-content-container-bg-color: #e5e5e5;
$routed-tab-panel-content-container-overview-bg-color: white;
$routed-tab-panel-content-bg-color: white;
$routed-tab-panel-color: $accent;
$routed-tab-panel-active-color: $primary;
$routed-tab-panel-first-child-border-left-color: $routed-tab-panel-bg-color;
$routed-tab-panel-child-border-right-color: $routed-tab-panel-bg-color;
$routed-tab-panel-child-hover-bg-color: $accent-l;
$routed-tab-panel-child-hover-color: $accent;
$routed-tab-panel-child-hover-box-shadow: none !important;
$routed-tab-panel-active-bg-color: $routed-tab-panel-bg-color;
$routed-tab-panel-child-active-box-shadow: none !important;
$routed-tab-title-color: $primary;
$routed-tab-panel-tile-bg-color: $routed-tab-panel-content-container-bg-color;
$routed-tab-panel-tile-color: $primary;
$routed-tab-panel-tile-hover-bg-color: $accent-l;
$routed-tab-panel-tile-hover-color: $accent;

$tab-bar-color: #f7f7f7;
$tab-bar-bg-color: $tab-bar-color;
$tab-bar-tab-bg-color: $tab-bar-color;
$tab-bar-tab-active-bg-color: $tab-bar-color;
$tab-bar-tab-border-color: $tab-bar-color;
$tab-bar-tab-hover-color: $accent;
$tab-bar-tab-hover-bg-color: $accent-l;

$dropdownlist-bg: white;
$dropdownlist-gradient: none;
$dropdownlist-hovered-bg: white;
$dropdownlist-hovered-gradient: none;

$primary-button-hovered-bg: $accent;

$tabstrip-item-selected-bg: #fcfcfc;
$tabstrip-content-bg: #fcfcfc;

@import "~@progress/kendo-theme-default/dist/all.scss";
@import "~@selas/ui-components/styles/all.scss";

a {
	text-decoration: none;
	color: inherit;

	&:active,
	&:focus {
		outline: none;
	}
}

pre {
	font-family: inherit;
}

.k-animation-container {
	z-index: 10003;
}

.k-loading-mask {
	z-index: 10005;
}

.k-dialog-content {
	.k-grid {
		border: none;

		.k-grid-toolbar {
			background-color: $tab-bar-color;
			border-bottom-color: rgba(0, 0, 0, 0.08);
		}

		.k-grid-header {
			border: 1px solid rgba(0, 0, 0, 0.08);
		}

		.k-grid-container {
			border: 1px solid rgba(0, 0, 0, 0.08);
		}
	}
}

.k-actions.k-actions-stretched {
	.k-button:not(:hover):not(.k-state-hover):not(:active):not(.k-state-active) {
		color: #e64700;
		background: none;
		border-color: inherit;
	}
}

.k-window {
	max-height: 95%;
	background-color: #f7f7f7;
}

.bodyContainer {
	padding: 20px;

	.bodyHeader {
		padding: 17px;
		height: 50px;
		background-color: $primary;
		color: white;
	}

	.body {
		background-color: white;
		height: calc(100% - 50px);
	}
}

#routedTabPanel {
	padding: 0px;

	.topBar {
		padding-left: 0px;
	}

	.contentContainer {
		padding: 20px;

		.content & & {
			background-color: white;
		}
	}
}

.green {
	background-color: #bfe7ad !important;
	color: #006100 !important;
}

.orange {
	background-color: #ffcc99 !important;
	color: #9c5700 !important;
}

.red {
	background-color: #ffc7ce !important;
	color: #9c0006 !important;
}

.toolbarButtonContainer {
	padding-right: 8px;

	.refreshButton {
		font-size: 24px;
		color: $accent;
		cursor: pointer;
	}

	&.noButtons {
		margin: 3px 0;
	}
}

.contactInformation {
	padding: 5px 30px 0;
	height: 80px;
}

.k-tabstrip {
	.k-animation-container.k-animation-container-relative {
		width: 100%;
	}
}

%status {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 15px;
	color: white;
	font-size: 23px;
}

.greenStatus::before {
	@extend %status;
	background-color: $greenBg;
}

.orangeStatus::before {
	@extend %status;
	background-color: $orangeBg;
}

.redStatus::before {
	@extend %status;
	background-color: $redBg;
}

.blueStatus::before {
	@extend %status;
	background-color: $blueBg;
}

.greyStatus::before {
	@extend %status;
	background-color: $greyBg;
}

.k-filtercell {
	.k-daterangepicker {
		.k-textbox-container {
			padding-top: 0;

			.k-label {
				display: none;
			}
		}

		.k-textbox-container:nth-child(2) {
			display: none;
		}
	}
}
