@import "../../../assets/colors.scss";

.home {
	padding: 50px 20px 20px 90px;

	.title {
		font-weight: 300;
		font-size: 2em;
		margin-bottom: 30px;

		.titleBold {
			font-weight: bold;
		}
	}

	.homeBox {
		background-color: white;
		margin-top: 20px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: $accent-l;
		}
	}

	.colHomeAction {
		flex: 0 0 410px;
		height: 260px;

		.homeBox {
			height: 100%;
			margin: 0 20px 20px 0;
			padding: 30px;
			background-size: 400px;
			background-repeat: no-repeat;
			background-position: bottom;

			.homeActionTitle {
				font-weight: bold;
				font-size: 25px;
			}
		}
	}

	#openDossiers {
		padding-right: 50px;

		.homeBox {
			display: flex;
			flex-direction: row;
			height: 90px;
			padding-top: 20px;

			h2 {
				padding-top: 10px;
			}

			.circle {
				height: 50px;
				width: 50px;
				border-radius: 30px;
				margin-right: 100px;
			}

			.green {
				background-color: green;
			}

			.orange {
				background-color: orange;
			}

			.red {
				background-color: red;
			}
		}
	}
}
